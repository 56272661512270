<template>
  <div class="visualization-footer">
    {{ label }}
  </div>
</template>

<script>

export default {
  name: 'VisualizationFooter',
  props: {
    label: {
      type: String,
      default: 'Stream can be late up to five seconds.',
    },
  },
};
</script>

<style lang="scss" scoped>
.visualization-footer {
  height: 18px;
  line-height: 18px;
  background-color: rgba(18, 19, 20, 0.7);
  width: 100%;
  text-align: center;
  font-size: 10px;
  color: var(--text-primary-1);
  pointer-events: none;
}
</style>
